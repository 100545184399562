<template>
  <div>
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard v-show="step === 1" class="p-4">
              <CCardBody>
                <CForm>
                  <p class="text-muted">Introduce el código de licencia</p>
                  <CInput
                    placeholder=""
                    autocomplete="false"
                    v-model="license"
                  />
                  <CRow v-if="error">
                    <CCol>
                      <CAlert color="danger" dismissible>
                        <strong>{{ errorMessage }}</strong>
                      </CAlert>
                    </CCol>
                  </CRow>
                  <CRow v-if="error">
                    <CCol>
                      <CAlert color="info" dismissible>
                        <a href="/#/commerceInventory">
                          <strong>Recuerda que en cualquier momento puedes adquirir tu licencia nueva en haciendo clic aqui</strong>
                      </a>
                      </CAlert>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="12" class="text-left">
                      <CButton color="primary" class="px-4" @click="loadLicenseData">
                        <CSpinner v-if="loading" variant="grow" size="sm"/>
                         Continuar  
                      </CButton>
                    </CCol>
                    <CCol col="12" class="text-right">
                      <span>No recuerdas el código?</span>
                      <CButton color="link" @click="forgot()">Haz cic aquí</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard v-show="step === 2" class="p-4">
              <CCardBody>
                <CForm>
                  <p class="text-muted">Nombre del producto</p>
                  <p>{{ product_name }}</p>
                  <br>
                  <p class="text-muted">Cantidad de equipos: <span style="color: black; font-weight: bold;">{{ quantity }}</span></p>
                  <vue-slider
                    v-model="quantity"
                    :min="0"
                    :max="5"
                    :interval="1"
                  ></vue-slider>
                  <br>
                  <p class="text-muted">Duración: <span style="color: black; font-weight: bold;">{{ duration }} (meses)</span></p>
                  <vue-slider
                    v-model="duration"
                    :min="12"
                    :max="36"
                    :interval="12"
                  ></vue-slider>
                  <br>
                  <CRow v-if="error">
                    <CCol>
                      <CAlert color="danger" dismissible>
                        <strong>{{ errorMessage }}</strong>
                      </CAlert>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol col="12" class="text-left">
                      <CButton color="primary" class="px-4" @click="loadLicenseData">
                        <CSpinner v-if="loading" variant="grow" size="sm"/>
                         Continuar  
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
            <CCard v-show="step === 3" class="p-4">
              <CCardBody>
                <CForm>
                  <h1>Renueva tu licencia ESET</h1><br/>
                  <p>{{ product_name }}</p>
                  <p class="text-muted">Duración: <span style="color: black; font-weight: bold;">{{ duration }} (meses)</span></p>
                  <p class="text-muted">Cantidad de equipos: <span style="color: black; font-weight: bold;">{{ quantity }}</span></p>
                  <CForm :validated="true">
                    <CCardBody>
                      <CCardTitle
                        ><center>Datos de Facturación</center></CCardTitle
                      >
                      <CInput
                        placeholder="Identificación"
                        v-model="form.identification"
                        required
                        :valid-feedback="validator.identificationValid"
                        :invalid-feedback="validator.identificationInvalid"
                        @blur="loadPartner"
                        @keypress="onlyNumberId"
                      >
                        <template #prepend-content>
                          <font-awesome-icon icon="id-card" />
                        </template>
                      </CInput>
                      <CRow>
                        <CCol xs="12" md="12" v-if="!completepart">
                          <CSelect
                            aria-label="Tipo de identificación"
                            v-model="form.identityType"
                            :options="identityType"
                            @change="partnerType"
                          >
                            <template #prepend-content
                              ><font-awesome-icon icon="id-card"
                            /></template>
                          </CSelect>
                        </CCol>
                        <CCol xs="12" md="12" v-if="!completepart">
                          <CSelect
                            aria-label="Tipo de socio de negocio"
                            v-model="form.societyType"
                            :options="societyType"
                            @change="partnerType"
                          >
                            <template #prepend-content
                              ><font-awesome-icon icon="id-card"
                            /></template>
                          </CSelect>
                        </CCol>
                      </CRow>
                      <CSelect
                        aria-label="Tipo de contribuyente"
                        v-model="form.typeTaxpayer"
                        v-show="false"
                        :options="typeTaxpayer"
                        @change="partnerType"
                        v-if="!completepart"
                      >
                        <template #prepend-content
                          ><font-awesome-icon icon="id-card"
                        /></template>
                      </CSelect>
                      <CInput
                        placeholder="Nombre"
                        v-model="name"
                        required
                        valid-feedback="validator.nameValid"
                        invalid-feedback="validator.nameInvalid"
                        :is-valid="validator.name"
                        v-if="(!completepart || !valid.cardname)"
                      >
                        <template #prepend-content
                          ><font-awesome-icon icon="user-tie"
                        /></template>
                      </CInput>
                      <CInput
                        placeholder="Apellido"
                        v-model="lastname"
                        required
                        valid-feedback="validator.nameValid"
                        invalid-feedback="validator.nameInvalid"
                        :is-valid="validator.name"
                        v-if="this.form.societyType !== '02' & (!completepart || !valid.cardname)"
                      >
                        <template #prepend-content
                          ><font-awesome-icon icon="user-tie"
                        /></template>
                      </CInput>
                      <CInput
                        type="email"
                        placeholder="Correo"
                        autocomplete="email"
                        v-model="form.email"
                        required
                        valid-feedback="validator.emailValid"
                        invalid-feedback="validator.emailInvalid"
                        :is-valid="validator.email"
                      >
                        <template #prepend-content
                          ><font-awesome-icon icon="at"
                        /></template>
                      </CInput>
                      <CInput
                        placeholder="Dirección"
                        v-model="form.address"
                        required
                        valid-feedback="validator.addressValid"
                        invalid-feedback="validator.addressInvalid"
                        :is-valid="validator.adress"
                        v-if="(!completepart || !valid.street)"
                      >
                        <template #prepend-content
                          ><font-awesome-icon icon="map-marker-alt"
                        /></template>
                      </CInput>
                      <CInput
                        placeholder="Ciudad"
                        v-model="form.city"
                        required
                        valid-feedback="validator.cityValid"
                        invalid-feedback="validator.cityInvalid"
                        :is-valid="validator.city"
                        v-if="(!completepart || !valid.city)"
                      >
                        <template #prepend-content
                          ><font-awesome-icon icon="city"
                        /></template>
                      </CInput>
                      <CInput
                        type="phone"
                        placeholder="Teléfono"
                        autocomplete="phone"
                        v-model="form.phone"
                        valid-feedback="validator.phoneValid"
                        invalid-feedback="validator.phoneInvalid"
                        @keypress="onlyNumber"
                      >
                        <template #prepend-content
                          ><font-awesome-icon icon="phone"
                        /></template>
                      </CInput>
                    </CCardBody>
                    <CCardBody>
                      <p style="text-align: right;">
                        Precio renovación
                        <span class="prices"
                          >${{ formatPrice(renovation.taxbaseamount) }}</span
                        >
                      </p>
                      <p style="text-align: right;">
                        Impuesto
                        <span class="prices"
                          >${{
                            formatPrice(renovation.total - renovation.taxbaseamount)
                          }}</span
                        >
                      </p>
                      <p style="text-align: right;">
                        Total
                        <span class="prices"
                          >${{ formatPrice(renovation.total) }}</span
                        >
                      </p>
                    </CCardBody>
                  </CForm>
                  <CRow v-if="alert.show">
                    <CAlert :color="alert.color" dismissible>
                      {{ alert.message }}
                    </CAlert>
                  </CRow>
                  <CRow v-show="!complete">
                    <CCol col="12" class="text-right">
                      <CCol col="12" class="text-left">
                        <CButton color="primary" class="px-4" @click="step = step - 1">
                          Regresar  
                        </CButton>
                      </CCol>
                      <CButton
                        class="js-payment-checkout"
                        color="info"
                        @click="payment()"
                      >
                        <font-awesome-icon icon="handshake" v-if="!loading" />
                        <CSpinner v-if="loading" variant="grow" size="sm" />
                        Continuar con el pago
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
      <br /><br />
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCard>
            <center>
              <p>Puedes compartir el siguiente enlace a tus clientes para que puedan realizar sus renovaciones:</p>
              <qrcode-vue :value="enlace" level="H" />
              <p>{{ this.enlace }}</p>
              <CButton
                class="js-payment-checkout"
                color="info"
                @click="copy"
              >
                <font-awesome-icon icon="copy" /> Copiar enlace
              </CButton>
            </center>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import axios from "axios";
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import QrcodeVue from "qrcode.vue";

export default {
  components: {
    VueSlider,
    QrcodeVue
  },
  data: function () {
    return {
      step: 1,
      license: '',
      name: "",
      lastname: "",
      form: {
        name: "",
        identification: "",
        email: "",
        address: "",
        phone: "",
        celphone: "",
        identityType: "02",
        societyType: "01",
        typeTaxpayer: "01",
        total: 0.0,
        taxbaseamount: 0.0,
        tax: 15.0,
        city: "",
      },
      validator: {
        identification: null,
        identificationValid: "",
        identificationInvalid: "",
        name: null,
        nameValid: "",
        nameInvalid: "",
        email: null,
        emailValid: "",
        emailInvalid: "",
        address: null,
        addressValid: "",
        addressInvalid: "",
        phone: null,
        phoneValid: "",
        phoneInvalid: "",
        city: null,
        cityValid: "",
        cityInvalid: "",
      },
      fields: [
        { key: "ItemName", label: "Nombre" },
        { key: "qty", label: "Cantidad" },
        { key: "Price", label: "Precio" },
      ],
      loading: false,
      complete: false,
      notfound: false,
      alert: {
        color: "danger",
        message: "success",
        show: false,
      },
      identityType: [
        { value: "02", label: "CEDULA" },
        { value: "01", label: "RUC" },
        { value: "03", label: "PASAPORTE" },
      ],
      societyType: [
        { value: "01", label: "Persona Natural" },
        { value: "02", label: "Sociedad" },
      ],
      typeTaxpayer: [
        {
          value: "01",
          label: "Persona Natural No obligada a llevar contabilidad",
        },
        {
          value: "02",
          label: "Persona Natural obligada a llevar contabilidad",
        },
        { value: "03", label: "Sociedad" },
        { value: "04", label: "Contribuyente Especial" },
        { value: "05", label: "Contribuyente Regimen Simplificado" },
        { value: "06", label: "Otros" },
        { value: "07", label: "Servicios Profesionales" },
      ],
      continue: false,
      completepart: false,
      validEmail: false,
      valid: {
        cardname: false,
        street: false,
        city: false,
      },
      taxbaseamount: 0,
      total: 0,
      error: false,
      errorMessage: null,
      userCode: null,
      renovation: {},
      quantity: 0,
      duration: 0,
      product_name: 'Antivirus protection',
      sku: null,
      enlace: '',
    };
  },
  async mounted() {
    this.enlace = process.env.VUE_APP_FRONTEND_URL+"/#/pages/renovation";
    this.userCode = this.$route.params.id;
    let paymentezScript = document.createElement("script");
    paymentezScript.setAttribute(
      "src",
      "https://cdn.paymentez.com/ccapi/sdk/payment_checkout_stable.min.js"
    );
    paymentezScript.setAttribute("charset", "UTF-8");
    document.head.appendChild(paymentezScript);

    let paymentezStyle = document.createElement("link");
    paymentezStyle.setAttribute(
      "href",
      'https://cdn.paymentez.com/ccapi/sdk/payment_stable.min.css" rel="stylesheet'
    );
    paymentezStyle.setAttribute("rel", "stylesheet");
    paymentezStyle.setAttribute("type", "text/css");
    document.head.appendChild(paymentezStyle);

    window.addEventListener("popstate", function () {
      paymentCheckout.close();
    });
    window.complete = this;
  },
  methods: {
    async loadLicenseData() {
      this.loading = true;
      this.error = false;
      const token = this.$store.state.user.token
      await axios
      .post(process.env.VUE_APP_BACKEND_URL+"/api/renovation/auth/data", 
        {
          license: this.license,
          quantity: this.quantity,
          duration: this.duration,
          product_name: this.product_name,
          sku: this.sku,
        },
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
      )
      .then(response => {
        if(response.data.message !== 'success'){
          this.error = true;
          this.errorMessage = response.data.data;
        } else {
          this.step++;
          this.quantity = response.data.data.quantity;
          this.product_name = response.data.data.product_name;
          this.duration = response.data.data.duration;
          this.renovation = response.data.data;
          this.sku = response.data.data.sku;

          this.$gtag.event('view_item_list', {
            item_list_id: "related_products",
            item_list_name: "Related products",
            items: [
              {
                item_id: this.sku,
                item_name: this.product_name,
                affiliation: null,
                coupon: null,
                discount: 0,
                index: 0,
                item_brand: null,
                item_category: "Renovation",
                item_category2: "ESD",
                item_category3: null,
                item_category4: null,
                item_category5: null,
                item_list_id: null,
                item_list_name: null,
                item_variant: null,
                location_id: null,
                price: this.renovation.taxbaseamount,
                quantity: 1
              }
            ]
          });
        }
      })
      .catch(error => {
        this.error = true;
        this.errorMessage = 'Error al conectar con el servidor';
      })
      .finally(() => {
        this.loading = false;
      });
    },
    partnerType() {
      if (this.form.identityType == "02") {
        this.form.societyType = "01";
      }
      if (this.form.societyType == "02") {
        this.form.typeTaxpayer = "03";
      } else {
        this.form.typeTaxpayer = "01";
      }
      this.validateCedula();
    },
    async loadPartner() {
      this.loading = true;
      await axios
      .get(process.env.VUE_APP_BACKEND_URL+"/api/partnerPublic/"+this.form.identification)
      .then((response) => {
        if(response.data){
          this.form.name = response.data.CardName;
          this.form.email = response.data.E_Mail;
          this.form.address = response.data.Street;
          this.form.phone = response.data.Phone1;
          this.form.celphone = response.data.Cellular;
          this.form.city = response.data.City;
          this.form.identityType = response.data.U_HBT_TIPO_ID;
          this.form.societyType = response.data.U_HBT_TIPPRO;
          this.form.typeTaxpayer = response.data.U_HBT_TIPCONT;
          this.name = response.data.CardName;
          this.lastname = "";

          this.valid.cardname = response.data.CardName;
          this.valid.street = response.data.Street;
          this.valid.city = response.data.City;
          this.continue = true;
          this.completepart = true;
          this.validator.identification = true;
          this.alert.show = true;
          this.alert.color = "success";
          this.alert.message = "Parece que ya eres clientes, valida tus datos para continuar";
        }else{
          this.valid.cardname = true;
          this.valid.street = true;
          this.valid.city = true;
          this.form.email = null;
          this.form.phone = null;
          this.continue = false;
          this.completepart = false;
          this.validateCedula();
          this.alert.show = false;
        }
      })
      .catch((error) => {
        this.form.email = null;
        this.form.phone = null;
        this.continue = false;
        this.completepart = false;
        this.validateCedula();
      }).finally(() => {
        this.loading = false;
      });
    },
    payment() {
      this.$gtag.event('begin_checkout', {
        currency: "USD",
        value: this.renovation.total,
        coupon: null,
        items: [
          {
            item_id: this.sku,
            item_name: this.product_name,
            affiliation: null,
            coupon: null,
            discount: 0,
            index: 0,
            item_brand: null,
            item_category: "Renovation",
            item_category2: "ESD",
            item_category3: null,
            item_category4: null,
            item_category5: null,
            item_list_id: null,
            item_list_name: null,
            item_variant: null,
            location_id: null,
            price: this.renovation.taxbaseamount,
            quantity: 1
          }
        ]
      });
      this.alert.show = false;
      this.form.name =
        this.lastname.toUpperCase() + " " + this.name.toUpperCase();
      if(this.continue){
        if(  (this.form.name !== "" &
          this.form.identification !== "" &
          this.form.email !== "" &
          this.form.address !== "" &
          this.form.phone !== "" &
          this.form.city !== "") || (this.completepart & 
          (this.valid.cardname || this.form.name !== "") & 
          (this.valid.street || this.form.address !== "") &
          (this.valid.city || this.form.city !== "")) 
        ){

          this.loading = true;
          this.form.name =
            this.lastname.toUpperCase() + " " + this.name.toUpperCase();
          var paymentCheckout = new PaymentCheckout.modal({
            client_app_code: process.env.VUE_APP_PAYMENTEZ_CODE,
            client_app_key: process.env.VUE_APP_PAYMENTEZ_KEY,
            locale: "es",
            env_mode: process.env.VUE_APP_PAYMENTEZ_ENV,
            onOpen: function () {
              //console.log('modal open');
            },
            onClose: function () {
              this.loading = false;
            },
            onResponse: async function (response) {
              var info = {};
              info.response = response;
              info.cart = window.complete.form;
              info.renovation = window.complete.renovation;
              info.quantity = window.complete.quantity;
              info.duration = window.complete.duration;
              info.product_name = window.complete.product_name;
              info.license = window.complete.license;
              info.sku = window.complete.sku;
              const token = window.complete.$store.state.user.token
              if (response.transaction.status === "success") {
                await axios
                  .post(process.env.VUE_APP_BACKEND_URL+"/api/renovation/auth/complete", info,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    })
                  .then((response) => {
                    if(response.data.message === 'success'){
                      window.complete.loading = false;
                      window.complete.complete = true;
                      window.complete.alert.color = "success";
                      window.complete.alert.message =
                        "Muchas gracias por completar su transaccion en breve recibira un correo";
                      window.complete.alert.show = true;
                      window.complete.$gtag.event('purchase', {
                        transaction_id: response.transaction.id,
                        value: info.response.transaction.amount,
                        tax: info.cart.tax,
                        shipping: 0,
                        currency: "USD",
                        coupon: null,
                        items: [
                          {
                            item_id: info.sku,
                            item_name: info.renovation.product_id,
                            affiliation: info.renovation.product_id,
                            coupon: null,
                            discount: 0,
                            index: 0,
                            item_brand: null,
                            item_category: "Renovation",
                            item_category2: "ESD",
                            item_category3: null,
                            item_category4: null,
                            item_category5: null,
                            item_list_id: null,
                            item_list_name: null,
                            item_variant: null,
                            location_id: null,
                            price: info.cart.taxbaseamount,
                            quantity: 1
                          }
                        ]
                      });
                    } else {
                      window.complete.loading = false;
                      window.complete.complete = true;
                      window.complete.alert.color = "danger";
                      window.complete.alert.message = response.data.message;
                      window.complete.alert.show = true;
                    }
                  })
                  .catch((error) => {
                    window.complete.loading = false;
                    window.complete.alert.color = "danger";
                    window.complete.alert.message =
                      "No se pudo completar la transaccion";
                    window.complete.alert.show = true;
                  });
              } else {
                window.complete.alert.color = "danger";
                window.complete.alert.message = response.transaction.message;
                window.complete.alert.show = true;
              }
            },
          });
          paymentCheckout.open({
            user_id: "1",
            user_email: this.form.email,
            user_phone: this.form.phone,
            order_description: this.form.address?this.form.address:'Renovacion',
            order_amount: this.roundToTwo(this.renovation.total),
            order_vat: this.roundToTwo(
              this.renovation.total - this.renovation.taxbaseamount
            ),
            //order_installments_type: 0, //0 corriente, 2 diferido con intereses, 3 diferido sin intereses
            order_taxable_amount: this.roundToTwo(this.renovation.taxbaseamount),
            order_tax_percentage: 15,
            order_reference: "#" + this.renovation.opportunity_id,
          });
        }else{
          this.alert.color = 'danger';
          this.alert.message = 'Debe completar todos los datos';
          this.alert.show = true;
        }
      }else{
        this.alert.color = 'danger';
        this.alert.message = 'Ingresar una cédula válida';
        this.alert.show = true;
      }
    },
    roundToTwo(num) {
      return +(Math.round(num + "e+2") + "e-2");
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    validateEmail(){
      this.validEmail = (email) => {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
      };
    },
    validateCedula() {
      if (this.form.identityType == "02") {
        const cedula = this.form.identification;
        if (cedula.length == 10) {
          var digito_region = cedula.substring(0, 2);
          if (digito_region >= 1 && digito_region <= 24) {
            var ultimo_digito = cedula.substring(9, 10);
            var pares =
              parseInt(cedula.substring(1, 2)) +
              parseInt(cedula.substring(3, 4)) +
              parseInt(cedula.substring(5, 6)) +
              parseInt(cedula.substring(7, 8));
            var numero1 = cedula.substring(0, 1);
            var numero1 = numero1 * 2;
            if (numero1 > 9) {
              var numero1 = numero1 - 9;
            }
            var numero3 = cedula.substring(2, 3);
            var numero3 = numero3 * 2;
            if (numero3 > 9) {
              var numero3 = numero3 - 9;
            }
            var numero5 = cedula.substring(4, 5);
            var numero5 = numero5 * 2;
            if (numero5 > 9) {
              var numero5 = numero5 - 9;
            }
            var numero7 = cedula.substring(6, 7);
            var numero7 = numero7 * 2;
            if (numero7 > 9) {
              var numero7 = numero7 - 9;
            }
            var numero9 = cedula.substring(8, 9);
            var numero9 = numero9 * 2;
            if (numero9 > 9) {
              var numero9 = numero9 - 9;
            }
            var impares = numero1 + numero3 + numero5 + numero7 + numero9;
            var suma_total = pares + impares;
            var primer_digito_suma = String(suma_total).substring(0, 1);
            var decena = (parseInt(primer_digito_suma) + 1) * 10;
            var digito_validador = decena - suma_total;
            if (digito_validador == 10) var digito_validador = 0;
            if (digito_validador == ultimo_digito) {
              this.validator.identification = true;
              this.validator.identificationValid = "Cédula correcta";
              this.continue = true;
            } else {
              this.validator.identification = false;
              this.validator.identificationInvalid = "Cédula incorrecta";
              this.continue = false;
            }
          } else {
            this.validator.identification = false;
            this.validator.identificationInvalid =
              "Cédula no pertenece a ninguna región";
            this.continue = false;
          }
        } else {
          this.validator.identification = false;
          this.validator.identificationInvalid = "Cédula debe tener 10 dígitos";
          this.continue = false;
        }
      } else {
        this.validator.identification = null;
        this.continue = true;
      }
    },
    async copy() {
      try {
        await navigator.clipboard.writeText(this.enlace);
        //alert('Copied');
      } catch ($e) {
        //alert('Cannot copy');
      }
    },
    onlyNumber ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },
    onlyNumberId ($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if(this.form.identityType !== "03"){
        if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
          $event.preventDefault();
        }
      }
    },
    forgot() {
      var url = "https://www.eset.com/ec/comprar/renovar/#content-encontrar-clave-licencia";
      window.open(url, '_blank');
    },
  },
  name: "Login",
};
</script>
